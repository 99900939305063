import React, { useEffect } from 'react';
import { AcademyEntity } from '@mb.io/catalog-model';

type CodelabOverlaysProps = {
  entity: AcademyEntity;
};

export function CodelabOverlays(props: CodelabOverlaysProps) {
  useEffect(() => {
    const handleCodelabReady = () => {
      // Set url for the Academy home page
      const doneButton = document.getElementById('done');
      if (doneButton) {
        doneButton.addEventListener('click', event => {
          event.preventDefault();
          window.history.back();
        });
      }

      const goBackButton = document.getElementById('arrow-back');
      if (goBackButton) {
        goBackButton.addEventListener('click', event => {
          event.preventDefault();
          window.history.back();
        });
      }

      // Only compute if the entity is already loaded
      const feedbackButton = document.getElementById('codelab-feedback');
      if (feedbackButton) {
        feedbackButton.addEventListener('click', event => {
          event.preventDefault();
          window.open(
            (props.entity as any).spec.codelab?.feedbackLink,
            '_blank',
            'noopener,noreferrer',
          );
        });
      }
    };

    document.addEventListener('google-codelab-ready', handleCodelabReady);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('google-codelab-ready', handleCodelabReady);
    };
  }, [props.entity]);

  return <></>;
}
