import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { configApiRef, useApi, useApp } from '@backstage/core-plugin-api';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import { PlatformFeatureFlagged } from '@mb.io/plugin-platform-feature-flag';
import { SIPCentralIcon } from '@mercedes-benz/sip-central';
import { SidebarSettings } from '@mercedes-benz/user-settings';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import Apps from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import MenuBook from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/MyLocation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { SearchModal } from '../search/SearchModal';
import { MBLogo } from './MBLogo';
import CloudIcon from '@mui/icons-material/Cloud';
import InsightsIcon from '@mui/icons-material/Insights';
import { SidebarSubmenuDivider } from '@mb.io/core-components';
import TerminalIcon from '@mui/icons-material/Terminal';
import { AppTitle } from './AppTitle';
import HandymanIcon from '@mui/icons-material/Handyman';
import WebhookIcon from '@mui/icons-material/Webhook';

const useSidebarLogoStyles = makeStyles()({
  root: {
    width: '100%',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
});

const useStyles = makeStyles()({
  sideBarLink: {
    '&:hover': {
      textDecoration: 'none',
    },
    '.MuiButton-label div:last-child': {
      width: 'auto',
      marginRight: '1em',
    },
  },
});
const SidebarLogo = () => {
  const { classes } = useSidebarLogoStyles();

  return (
    <div className={classes.root}>
      <Link component={NavLink} to="/" underline="none">
        <MBLogo />
      </Link>
    </div>
  );
};

const FlaggedShortcuts = () => (
  <PlatformFeatureFlagged feature="shortcuts">
    <Shortcuts />
  </PlatformFeatureFlagged>
);

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { classes } = useStyles();
  const config = useApi(configApiRef);
  const isDevtoolsEnabled = config.getOptionalBoolean('devtools.enabled');

  return (
    <SidebarPage>
      <Sidebar disableExpandOnHover>
        <SidebarLogo />
        <AppTitle />
        <SidebarSearchModal>
          {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
        </SidebarSearchModal>
        <SidebarDivider />
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon as never} to="/home" text="Home" />
        <SidebarItem
          icon={Apps as never}
          text="Catalog"
          className={classes.sideBarLink}
        >
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="/catalog?filters[kind]=domain"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="/catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Components"
              to="/catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <PlatformFeatureFlagged feature="seamless">
              <SidebarSubmenuItem
                icon={useApp().getSystemIcon('kind:seamless')}
                to="/seamless"
                title="Seamless"
              />
            </PlatformFeatureFlagged>
            <SidebarSubmenuItem
              icon={useApp().getSystemIcon('kind:api')}
              to="/api-docs"
              title="APIs"
            />
            <SidebarSubmenuDivider />
            <SidebarSubmenuItem
              title="Infrastructure"
              to="/catalog?filters[kind]=infrastructure"
              icon={useApp().getSystemIcon('kind:infrastructure')}
            />
            <SidebarSubmenuDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="/catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="/catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={LibraryBooks as never} to="/docs" text="Docs" />
        <SidebarItem icon={MenuBook as never} to="/esg" text="Guidelines" />
        <SidebarItem
          icon={SchoolRoundedIcon as never}
          to="/academy"
          text="Academy"
        />
        <SidebarItem icon={LayersIcon as never} to="/explore" text="Explore" />
        <SidebarItem
          icon={CreateComponentIcon as never}
          to="/create"
          text="Create..."
        />
        <SidebarDivider />
        <SidebarItem
          icon={CloudIcon as never}
          text="cloudX"
          className={classes.sideBarLink}
        >
          <SidebarSubmenu title="cloudX">
            <PlatformFeatureFlagged feature="tdms-next">
              <SidebarSubmenuItem
                subtitle="Provided by Nebula"
                icon={EngineeringIcon as never}
                to="/tdms-next"
                title="TDMS"
              />
            </PlatformFeatureFlagged>
            <SidebarSubmenuItem
              subtitle="Provided by Nebula"
              icon={InsightsIcon as never}
              to="/pmas"
              title="PMAS"
            />
            <SidebarSubmenuDivider />
            <SidebarSubmenuItem
              subtitle="Provided by SIP"
              icon={SIPCentralIcon}
              to="/sip-central"
              title="SIP Central"
            />
            <PlatformFeatureFlagged feature="seccomp">
              <SidebarSubmenuItem
                subtitle="Provided by SIP"
                icon={SecuritySharpIcon as never}
                to="/seccomp"
                title="Compliance"
              />
            </PlatformFeatureFlagged>
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem
          icon={HandymanIcon as never}
          text="Tools"
          className={classes.sideBarLink}
        >
          <SidebarSubmenu title="Tools">
            <SidebarSubmenuItem
              title="DWS"
              to="/dws"
              icon={WebhookIcon as never}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem
          icon={MapIcon as never}
          to="/tech-radar"
          text="Tech Radar"
        />
        <SidebarItem
          icon={StorefrontIcon as never}
          to="/bazaar"
          text="Bazaar"
        />
        {/* End global nav */}
        <FlaggedShortcuts />
        <SidebarSpace />
        <SidebarDivider />
        <SidebarSettings />
        {isDevtoolsEnabled && (
          <RequirePermission
            permission={devToolsAdministerPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={TerminalIcon as never}
              to="/devtools"
              text="DevTools"
            />
          </RequirePermission>
        )}
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
