export enum UserRole {
  ACCOUNT_MEMBER = 'ACCOUNT_MEMBER',
  ACCOUNT_OWNER = 'ACCOUNT_OWNER',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  TEAM_OWNER = 'TEAM_OWNER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type AwsRole = {
  id: string;
  name: string;
};

export type AWSAccountDetail = {
  owner: string;
  id: string;
  description: string;
  awsRoles: AwsRole[];
};

export type awsAccount = {
  awsAccountId: string;
  roles: UserRole[];
  awsRoles: AwsRole[];
  awsAccountDetail: AWSAccountDetail;
  prod: boolean;
  catalogTestAccount: boolean;
};

export enum actionEnum {
  ADD_USER = 'ADD_USER',
  CREATE_TEAM = 'CREATE_TEAMS',
  UPDATE_TEAM = 'UPDATE_TEAMS',
  DELETE_USER = 'DELETE_USER',
  DELETE_TEAM = 'DELETE_TEAM',
  REASSIGN_USERS = 'REASSIGN_USERS',
  ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
  GRANT_PERMISSION = 'GRANT_PERMISSION',
  REVOKE_PERMISSION = 'REVOKE_PERMISSION',
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',
  ADD_PERMISSION = 'ADD_PERMISSION',
  REMOVE_PERMISSION = 'REMOVE_PERMISSION',
}

export type permissionDto = {
  awsRoleRequests?: AWSRoleRequest[];
  instance?: string;
  roles?: string[];
};
export type CreateOrUpdateTeamDto = {
  currentTeam: {
    name?: string;
    manager?: string;
    owner?: string;
  };
  updatedTeam?: {
    name: string;
    manager?: string;
  };
};
export type ToolPermissions = {
  tool: string;
  permissions: permissionDto[];
};

export type activityDto = {
  performedOn?: string;
  toolPermissions?: ToolPermissions;
  teamMembers?: AssignMember[];
  teamDetails?: CreateOrUpdateTeamDto[];
};

export type AuditLogsDto = {
  currentUser: string | undefined;
  action: actionEnum;
  activities?: activityDto[] | void[];
  actionDate: string;
  currentTeam?: string;
};

export type InstanceName = {
  instanceName: string | null;
};

export type UserId = {
  userId: string;
};

export type AwsAccountId = {
  awsAccountId: string;
};

export type BlueBoxAwsAccountDto = {
  accountId: string;
  mbId: string;
  stage: string;
  orgName: string;
  teamName: string;
  blockedPlugins: string[];
  teamDomain: string[];
  cloudEngineVersion: string;
};

export type InstallCatalogPluginDto = {
  awsAccountId: string;
  productName: string;
  leanIx: string;
  pluginId: string;
  config: string;
  userId: string;
  pluginVersion: string[];

  namespace?: string;
};

export type TeamId = {
  teamId: string;
};

export type WiwId = {
  wiwId: string;
};

export type UserDto = {
  id?: string | undefined;
  awsAccounts?: awsAccount[];
};

export type WiwIdDto = {
  id: string;
  givenName: string;
  surname: string;
};

export type LeanIxTeamDto = {
  id: string;
  name: string;
  leanIxId: string;
  mbgAppShortName: string;
  description: string;
  release: string;
};

export type TeamDto = {
  id: string;
  name: string;
  manager?: string;
  owner: string;
  oldTeam?: string;
  oldManager?: string;
};

export type ToolDto = {
  id: string;
  name: string;
};

export type TeamUserRole = {
  team: TeamDto;
  teamRole: string;
};

export type UserTeam = {
  id: string;
  teams: TeamUserRole[];
};

export type TeamsDto = {
  teams: TeamDto[];
};

export type CreateTeamsDto = {
  teams: CreateTeamDto[];
};

export type AssignMemberDTO = {
  id: string;
  teamRole: string;
  teams: string[];
};

export type EventId = {
  eventId: string;
};

export type InstallPluginDTO = {
  entries: EventId[];
  failedEntryCount: number;
};

export type AssignMember = {
  id?: string;
  teamRole?: string;
  teams?: string[];
  reAssignedTeamNames?: string[];
  teamNames?: string[];
};

export type InstallPlugin = {
  accountId: string;
  accountName: string;
};

export type AWSRoleRequest = {
  awsAccountId: string;
  awsRoleIds: string[];
  awsAccountRoleId: string;
  awsSelectedRoles: string[];
  awsInstance: string;
  awsExistingRoles: string[];
};

export type CreateAccess = {
  id: string;
  awsRoleRequests: AWSRoleRequest[];
};

export type UpdateAccess = {
  awsRoleRequests: AWSRoleRequest[];
};

export type DeleteAccess = {
  awsAccountIds: AWSDeleteAccessRequest[];
};

export type AWSDeleteAccessRequest = {
  awsAccountId: string;
  awsInstance: string;
};

export type CreateAccessDTO = {
  status: string;
};

export type UpdateAccessDTO = {
  status: string;
};
export type RemoveAccessDTO = {
  status: string;
};

export type RemoveMemberDTO = {
  status: string;
};

export type ReassignTeamMembersDTO = {
  status: string;
};

export type RemoveTeamDTO = {
  status: string;
};

export type AssignMembers = {
  members: AssignMember[];
  teamName?: string;
};

export type AssignMembersDTO = {
  members: AssignMember[];
};

export type CreateTeamDto = {
  name: string;
  owner: string;
  manager?: string;
};

export type AwsAccountUser = {
  id: string;
  email?: string;
  firstName: string;
  surname: string;
};

export type TeamUsers = {
  teamUsers: TeamUser[] | undefined;
};

export type TeamUser = {
  id?: string;
  name?: string;
  role?: string;
  team?: string[];
  wiwId?: string;
  teams?: TeamUserRole[];
  [key: string]: any;
};

export type TeamMemberName = {
  givenName: string;
  surname: string;
};

// SonarQube
export type Group = {
  id: string;
  name: string;
  membersCount: number;
  default: boolean;
};

export type Paging = {
  pageIndex: number;
  pageSize: number;
  total: number;
};

export type SearchUserGroupsResponse = {
  paging: Paging;
  groups: Group[];
};

export type InstanceUserGroups = {
  name: String;
  groups: Group[];
};

export interface InstanceUsers {
  instanceName: string;
  users: UserInfo[];
}

export interface UserInfo {
  login: string;
  name: string;
  active: boolean;
  groups: string[];
  tokensCount: number;
  local: boolean;
  externalIdentity: string;
  externalProvider: string;
  lastConnectionDate: string;
}

export interface MultiUserGroupRequest {
  instanceName?: string;
  groups: string[];
  loginName: string;
}

export interface UserGroupResponse {
  success: boolean;
  error?: string;
}

export interface SearchPermissionTemplatesResponse {
  permissionTemplates: PermissionTemplate[];
}

export interface PermissionTemplate {
  id: string;
  name: string;
  projectKeyPattern: string;
  createdAt: string;
  updatedAt: string;
  permissions: Permission[];
}

interface Permission {
  key: string;
  usersCount: number;
  groupsCount: number;
  withProjectCreator: boolean;
}

export interface GroupPermission {
  name: string;
  permissions: PermissionList[];
}

export type SonarUserGroupsDto = {
  users: SonarUser[];
};

export type TeamInfo = {
  team: Team;
  ownerId: string;
  teamMember: Team;
};

export type Team = {
  name: string;
  id: string;
  teamRole: string;
};

export type SonarUser = {
  name: string;
  groups: string[];
  instanceName: string;
  users: any;
};
export type PermissionList =
  | 'admin'
  | 'codeviewer'
  | 'issueadmin'
  | 'securityhotspotadmin'
  | 'scan'
  | 'user';

// Jenkins
export type JenkinsUserGroupsDto = {
  users: JenkinsUser[];
};

export type JenkinsUser = {
  instanceName: string;
  projectRoles: string[];
};

export type MultiGitHubUserGroupClusterRequest = MultiGitHubUserGroupRequest & {
  teamId: string;
  userGroupType: string;
};

export type MultiGitHubUserGroupRequest = {
  organization: string;
  groups: string[];
  loginName: string;
};

export type GitHubUserOrganizationTeam = {
  data: {
    organization: {
      teams: {
        edges: {
          node: {
            name: string;
          };
        }[];
      };
    };
  };
};

export type PluginDto = {
  id: string;
  pluginName: string;
  pluginDescription: string;
  billable: boolean;
  price?: number;
  pluginDocumentLink: string;
  mandatory: boolean;
  raiseRequestLink: string;
  buttonRaiseRequest: string;
  version?: string;
  active: boolean;
  multiInstance: boolean;
  paymentInfo: string | null;
  paymentType: string | null;
};

export type PlayListDto = {
  id: string;
  name: string;
  description: string;
  documentLink: string;
};

export type PluginsAndPlayListsDto = {
  plugins: PluginDto[];
  playlists: PlayListDto[];
};

export type UpdateDto = {
  available: boolean;
  version: string;
};

export type InstalledPluginDto = {
  id: string;
  accountId: string;
  pluginName: string;
  version: string;
  buildId: string;
  buildStatus: string;
  requestedOn: string;
  updatedOn: string;
  billable: boolean;
  eventId: string;
  productName: string;
  leanIx: string;
  config: string;
  pluginId: string;
  mandatory: boolean;
  type: string;
  namespace: string;
  multiInstance: boolean;
  update: UpdateDto;
  repoId: string;
  supportLink: string;
  serviceNowLink: string;
  changeLogLink: string;
  documentationLink: string;
};

export type InstalledPluginsDTO = {
  pluginRequests: InstalledPluginDto[];
};
export enum UserGroupType {
  Internal = 'internal',
  External = 'external',
}

export enum UserType {
  Daimler = 'daimler',
  Supplier = 'supplier',
}

export type InstalledPluginDetailDTO = {
  productName: string;
  pluginName: string;
  planningItId: string;
  accountId: string;
  mbId: string;
  orgName: string;
  teamName: string;
  stage: string;
  buildStatus: string;
  requestedOn: string;
  pluginVersion: string;
  adminList: [];
};

export type PluginCheckLogDto = {
  pluginLogs: string;
  pluginLogUrl: string;
};

export type TransitService = {
  id: string;
  name: string;
  stage: string;
  defaultService: boolean;
  mandatory: boolean;
};
